import { graphql } from '../generated-gql';

export const queryMobileOrderPickupCode = graphql(`
  query MobileOrderPickupCode($orderId: ID!) {
    order(id: $orderId) {
      id
      pickupCode
      totalPrice
    }
  }
`);

export const queryAdminOrderDetails = graphql(`
  query AdminOrderDetails($orderId: ID!) {
    order(id: $orderId) {
      id
      user {
        id
        firstName
        lastName
        email
      }
      pickupKioskId
      pickupCode
      orderPlacedFrom
      orderStartedTimestamp
      paymentAttemptedTimestamp
      paymentCompletedTimestamp
      orderCompletedTimestamp
      totalPrice
      status
      userEmail
      orderDetails {
        price
        quantity
        card {
          id
          cardName
          isActive
          sku
          images {
            previewMainPhotoUrl
            mainImageDescription
          }
        }
        giftCardTransaction {
          denomination
          vendor {
            name
          }
        }
      }
    }
  }
`);

export const queryOrderDetails = graphql(`
  query OrderDetails($orderId: ID!) {
    order(id: $orderId) {
      id
      city
      country
      line1
      line2
      name
      postalCode
      pickupCode
      state
      selfOrder
      totalPrice
      orderPlacedFrom
      orderStartedTimestamp
      paymentAttemptedTimestamp
      paymentCompletedTimestamp
      orderCompletedTimestamp
      status
      userEmail
      orderDetails {
        price
        quantity
        card {
          id
          cardName
          isActive
          sku
          images {
            previewMainPhotoUrl
            mainImageDescription
          }
        }
        giftCardTransaction {
          denomination
          vendor {
            name
          }
        }
      }
    }
  }
`);
