import { graphql } from '../generated-gql';

export const queryKiosks = graphql(`
  query Kiosks($input: KiosksInput) {
    kiosks(input: $input) {
      id
      isActive
      cardMaxRating
      maintenanceScreen
      disableGiftCards
      autoCoupon
    }
  }
`);
